<template>
  <div class="w-full h-full">
    <div class="flex justify-end">
      <el-button type="primary" icon="el-icon-plus" size="medium" @click="onNewIpClicked()">
        {{ $t('barcode_type.add_new_rule') }}
      </el-button>
    </div>
    <div class="flex-grow flex flex-col">
      <div class="my-8 flex-grow table-container">
        <el-table
          :data="whiteListIp"
          class="table-content rounded-xl w-full"
          header-cell-class-name="custom-table__header-cell"
          cell-class-name="custom-table__cell cursor-pointer"
          border
          :empty-text="$t('empty')"
          highlight-current-row
        >
          <el-table-column prop="startIp" :label="$t('ip_address')">
            <template v-slot="scope">
              <span>
                {{
                  `${scope.row.startIp} ${
                    scope.row.endIp && scope.row.endIp !== scope.row.startIp ? '~ ' + scope.row.endIp : ''
                  }`
                }}</span
              >
            </template>
          </el-table-column>
          <el-table-column :label="$t('carrier.remarks')" prop="note" />
          <el-table-column :width="200" :label="$t('actions')">
            <template v-slot="scope">
              <div class="text-center">
                <el-button
                  @click.stop="openEditIpDialog(scope.$index)"
                  type="primary"
                  size="medium"
                  class="text-white focus:text-white"
                >
                  {{ $t('edit') }}
                </el-button>
                <el-button
                  type="default"
                  class="bg-red-min text-white focus:text-white"
                  size="medium"
                  @click.stop="removeIpRange(scope.$index)"
                >
                  {{ $t('delete') }}
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
  <WhiteListIPDetailDialog
    :idx="selectedIdx"
    v-model:whiteListIP="whiteListIp"
    v-model:isShowDialog="isShowDetailDialog"
    @dialog:update-ok="onWhiteListIpDetailUpdated"
  />
</template>

<script lang="ts">
import { PropSync } from 'vue-property-decorator'
import { IIpRange } from 'smartbarcode-web-core/src/utils/types/index'
import WhiteListIPDetailDialog from '@/components/whiteListIp/WhiteListIPDetailDialogComponent.vue'

import { Options, Vue } from 'vue-class-component'
@Options({
  components: { WhiteListIPDetailDialog },
  name: 'WhiteListIPComponent',
  emits: ['dialog:close', 'dialog:update-ok', 'update:modelValue'],
})
export default class WhiteListIPComponent extends Vue {
  @PropSync('modelValue', { type: Object }) _modelValue?: IIpRange[]
  isShowDetailDialog = false
  selectedIdx = -1

  get whiteListIp() {
    if (!this._modelValue) {
      this.$emit('update:modelValue', [])
      this._modelValue = []
    }
    return this._modelValue
  }

  set whiteListIp(newVal) {
    this.$emit('update:modelValue', newVal)
  }

  onWhiteListIpDetailUpdated(ipRange: IIpRange) {
    if (this.selectedIdx < 0) {
      this.whiteListIp.push(ipRange)
    } else {
      this.whiteListIp[this.selectedIdx] = ipRange
    }
  }

  removeIpRange(idx: number) {
    this.$confirm(this.$t('confirm_delete_ip_address'), this.$t('info'), {
      confirmButtonText: this.$t('update'),
      confirmButtonClass: 'danger',
      cancelButtonText: this.$t('cancel'),
    })
      .then(() => {
        this.whiteListIp.splice(idx, 1)
      })
      .catch(() => true)
  }

  openEditIpDialog(idx: number) {
    this.selectedIdx = idx
    this.isShowDetailDialog = true
  }

  onNewIpClicked() {
    this.selectedIdx = -1
    this.isShowDetailDialog = true
  }
}
</script>
