<template>
  <Dialog
    class="custom-dialog"
    v-model="isShowDialog"
    @dialog:update-ok="onSavedWhiteList()"
    :title="title"
    :okButtonTitle="okButtonTitle"
  >
    <WhiteListIPComponent v-model="whiteListIpModel" />
  </Dialog>
</template>

<script lang="ts">
import { PropSync, Prop } from 'vue-property-decorator'
import { Options, Vue } from 'vue-class-component'
import Dialog from '@/components/common/Dialog.vue'
import WhiteListIPComponent from '@/components/whiteListIp/WhiteListIPComponent.vue'
import cloneDeep from 'lodash/cloneDeep'

@Options({
  name: 'WhiteListIPDialogComoponent',
  components: { Dialog, WhiteListIPComponent },
  emits: ['dialog:update-ok', 'update:modelValue'],
})
export default class WhiteListIPDialogComoponent extends Vue {
  @PropSync('modelValue', { type: Boolean }) _modelValue?: boolean
  @Prop({ type: String }) readonly title?: string
  @Prop({ type: String }) readonly okButtonTitle?: string
  whiteListIpModel = []

  get isShowDialog() {
    if (this._modelValue === true) {
      this.whiteListIpModel = cloneDeep(this.whiteListIPStore)
    }
    return this._modelValue
  }

  set isShowDialog(newVal) {
    this.$emit('update:modelValue', newVal)
  }

  get whiteListIPStore() {
    return this.$store.getters.whiteListIp
  }

  async onSavedWhiteList() {
    this.$emit('dialog:update-ok', this.whiteListIpModel)
  }
}
</script>
<style lang="scss" scoped>
.custom-dialog:deep() {
  .pre-line {
    & .el-form-item__label {
      line-height: 20px;
    }
  }
  &.el-overlay {
    .el-dialog {
      &.el-dialog--custom {
        width: 1000px;
        max-width: 1000px;
        margin-top: 0px;
      }
    }
  }
}
</style>
