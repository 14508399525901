
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import PageHeader from '@/components/common/PageHeader.vue'
import DataTableMixin from '@/components/mixins/DataTableMixin.vue'
import WhiteListIPDialog from '@/components/whiteListIp/WhiteListIPDialogComponent.vue'
import { LOAD_ENTERPRISE_LIST, SAVE_WHITE_IP_LIST, SET_HAVE_NEW_EXPORT_RECORD } from '@/store/actions'
import { getAccessLog } from '@/utils/api'
import { FILTER_DEBOUNCE_TIME } from '@/utils/constants'
import errorHandler from '@/utils/errorHandler'
import { isAfterDate, openMessage } from '@/utils/utils'
import debounce from 'lodash/debounce'
import moment from 'moment'
import { EExportType, ESmartBarcodeMenu } from 'smartbarcode-web-core/src/utils/enums/index'
import { ICommonSearch, IEnterpriseList, IIpRange } from 'smartbarcode-web-core/src/utils/types/index'
import { Options } from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'

@Options({
  components: { ItemNotFound, PageHeader, WhiteListIPDialog },
  name: ESmartBarcodeMenu.ENTERPRISE,
})
export default class Enterprise extends Mixins(DataTableMixin) {
  dataSearch = {} as ICommonSearch
  loading = false
  isShowDialog = false
  isShowWhiteListIpDialog = false

  dateModel: Record<string, string> = { date: moment().toString() }

  get years() {
    const years = []
    for (let i = 2000; i < 2100; i++) years.push(i)
    return years
  }

  get months() {
    const months = []
    for (let i = 1; i <= 12; i++) months.push(i.toString().padStart(2, '0'))
    return months
  }

  get getItems(): IEnterpriseList[] {
    return [...this.$store.state.enterprise?.enterprises]
  }

  async onWhiteListIPDialogSave(whiteListIP: IIpRange) {
    try {
      this.loading = true
      await this.$store.dispatch(SAVE_WHITE_IP_LIST, whiteListIP)
      openMessage(this.$t('save_successful'), 'success')
      this.isShowWhiteListIpDialog = false
    } catch (error) {
      errorHandler(error)
    } finally {
      this.loading = false
    }
  }

  disabledDate(date: Date) {
    return isAfterDate(date)
  }

  onCloseDialog() {
    this.isShowDialog = false
  }

  onChangeMonth(month: number) {
    return month.toString().length < 2 ? '0' + month.toString() : month.toString()
  }

  async onExportBarcode() {
    try {
      this.loading = true
      const month = this.onChangeMonth(new Date(this.dateModel.date).getMonth() + 1)
      const year = new Date(this.dateModel.date).getFullYear()
      await getAccessLog(`${year}-${month}`)
      this.$store.commit(SET_HAVE_NEW_EXPORT_RECORD, { exportType: EExportType.ACCESS_LOG, isHaveNew: true })
    } catch (error) {
      errorHandler(error)
    } finally {
      this.isShowDialog = false
      this.loading = false
    }
  }

  filterItems() {
    const cleanSearchText = this.cleanString(this.dataSearch.text)
    this.displayItems = this.getItems?.filter(
      (enterprise: IEnterpriseList) =>
        this.cleanString(enterprise.fullName || '').includes(cleanSearchText) ||
        this.cleanString(enterprise.email || '').includes(cleanSearchText) ||
        this.cleanString(enterprise.id || '').includes(cleanSearchText) ||
        this.cleanString(enterprise.organizationId || '').includes(cleanSearchText)
    )

    this.sortDisplayItems()
  }

  refreshItemList() {
    this.debounceFilter()
  }

  async fetchList() {
    this.loading = true
    try {
      await this.$store.dispatch(LOAD_ENTERPRISE_LIST).finally(() => {
        this.loading = false
      })
      this.filterItems()
    } catch (error) {
      errorHandler(error)
    }
  }

  getClassTableRow(index: number, total: number) {
    let className = index === this.currentRowIndex ? 'bg-blue-200' : 'bg-white'

    if (index !== total - 1) {
      className = `${className} border-solid border-b-1 border-gray-300`
    }

    return className
  }

  onCreateEnterprise() {
    this.$router.push({ name: 'enterpriseCreate' })
  }

  openAccessLogOutput() {
    this.isShowDialog = true
  }

  openWhiteListIpdialog() {
    this.isShowWhiteListIpDialog = true
  }

  onEditEnterprise(enterprise: IEnterpriseList) {
    this.$router.push({
      name: 'enterpriseEdit',
      params: {
        id: enterprise.id,
      },
    })
  }

  async created() {
    this.routeName = 'enterprise'
    this.getQueryData()

    if (!this.$store.state.enterprise.isLoaded) {
      await this.fetchList()
    } else {
      this.filterItems()
    }

    this.debounceFilter = debounce(() => {
      this.filterItems()
    }, FILTER_DEBOUNCE_TIME)
  }
}
