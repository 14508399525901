<template>
  <div v-loading.fullscreen="loading" class="flex flex-col h-full">
    <div class="flex items-center pb-8 border-solid border-b-1 border-gray-300 header-container">
      <div class="flex-grow items-center flex header-container__left">
        <PageHeader :title="$t('enterprise.admin_menu')" @load:refreshList="fetchList" />
      </div>
      <div class="flex header-container__right">
        <el-button class="mr-8" type="primary" icon="el-icon-plus" size="medium" @click="onCreateEnterprise()">
          {{ $t('enterprise.new_enterprise') }}
        </el-button>

        <el-button class="mr-8 ml-0" type="primary" size="medium" @click="openWhiteListIpdialog()">
          {{ $t('enterprise.ip_restriction') }}
        </el-button>

        <el-button class="mr-8 ml-0" type="primary" size="medium" @click="openAccessLogOutput()">
          {{ $t('enterprise.access_log_output') }}
        </el-button>

        <el-input
          class="w-60"
          prefix-icon="el-icon-search"
          v-model="dataSearch.text"
          @keyup="refreshItemList"
          @blur="updateTextSearch"
        />
      </div>
    </div>

    <div v-if="displayItems.length > 0" class="flex-grow flex flex-col">
      <div class="my-16 flex-grow table-container">
        <el-table
          :data="displayItems"
          class="rounded-xl w-full"
          header-cell-class-name="custom-table__header-cell"
          cell-class-name="custom-table__cell cursor-pointer"
          border
          :empty-text="$t('empty')"
          highlight-current-row
          @current-change="onEditEnterprise"
        >
          <el-table-column prop="fullName" :label="$t('full_name')" />
          <el-table-column prop="email" :label="$t('email_address')" />
          <el-table-column prop="roleType" :label="$t('enterprise.role')">
            <template v-slot="scope">
              {{ !!scope.row.roleType ? $t(`roleType.${scope.row.roleType}`) : '' }}
            </template>
          </el-table-column>
          <el-table-column prop="isActive" :label="$t('status')">
            <template v-slot="scope">
              {{ scope.row.isActive ? $t('active') : $t('inactive') }}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="justify-center flex flex-row mb-16 paging-container">
        <el-pagination
          layout="prev, pager, next"
          :total="getItems.length"
          :page-size="itemsPerPage"
          :current-page="currentPageNum"
          @current-change="currentPageChange($event)"
          background
          class="custom-pagination"
        />
      </div>
    </div>

    <template v-else>
      <ItemNotFound class="mt-56" :content="$t('empty')" />
    </template>
  </div>
  <el-dialog
    :custom-class="'el-dialog--custom'"
    v-model="isShowDialog"
    top="0"
    :destroy-on-close="false"
    :close-on-click-modal="false"
    :show-close="true"
    @close="onCloseDialog"
  >
    <el-form status-icon :label-position="'right'" label-width="220px" :model="dateModel" ref="exportDateForm">
      <div class="flex justify-evenly">
        <div class="self-center">{{ $t('export_date') }}</div>
        <div class="search-date-container">
          <el-date-picker
            :disabledDate="disabledDate"
            :clearable="false"
            v-model="dateModel.date"
            type="month"
            class="w-20"
            :editable="false"
          />
        </div></div
    ></el-form>

    <template #title>
      {{ `${$t('enterprise.access_log_output')} ` }}
    </template>
    <template #footer>
      <div class="flex">
        <div class="flex-1">
          <el-button type="default" class="btn-default-cancel" @click="onCloseDialog">
            {{ $t('cancel') }}
          </el-button>
        </div>
        <div class="flex-1">
          <el-button type="primary" @click="onExportBarcode()">
            {{ $t('timetable.process') }}
          </el-button>
        </div>
      </div>
    </template>
  </el-dialog>
  <WhiteListIPDialog
    @dialog:update-ok="onWhiteListIPDialogSave"
    v-model="isShowWhiteListIpDialog"
    :title="$t('enterprise.ip_restriction')"
    :okButtonTitle="$t('save')"
  />
</template>

<script lang="ts">
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import PageHeader from '@/components/common/PageHeader.vue'
import DataTableMixin from '@/components/mixins/DataTableMixin.vue'
import WhiteListIPDialog from '@/components/whiteListIp/WhiteListIPDialogComponent.vue'
import { LOAD_ENTERPRISE_LIST, SAVE_WHITE_IP_LIST, SET_HAVE_NEW_EXPORT_RECORD } from '@/store/actions'
import { getAccessLog } from '@/utils/api'
import { FILTER_DEBOUNCE_TIME } from '@/utils/constants'
import errorHandler from '@/utils/errorHandler'
import { isAfterDate, openMessage } from '@/utils/utils'
import debounce from 'lodash/debounce'
import moment from 'moment'
import { EExportType, ESmartBarcodeMenu } from 'smartbarcode-web-core/src/utils/enums/index'
import { ICommonSearch, IEnterpriseList, IIpRange } from 'smartbarcode-web-core/src/utils/types/index'
import { Options } from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'

@Options({
  components: { ItemNotFound, PageHeader, WhiteListIPDialog },
  name: ESmartBarcodeMenu.ENTERPRISE,
})
export default class Enterprise extends Mixins(DataTableMixin) {
  dataSearch = {} as ICommonSearch
  loading = false
  isShowDialog = false
  isShowWhiteListIpDialog = false

  dateModel: Record<string, string> = { date: moment().toString() }

  get years() {
    const years = []
    for (let i = 2000; i < 2100; i++) years.push(i)
    return years
  }

  get months() {
    const months = []
    for (let i = 1; i <= 12; i++) months.push(i.toString().padStart(2, '0'))
    return months
  }

  get getItems(): IEnterpriseList[] {
    return [...this.$store.state.enterprise?.enterprises]
  }

  async onWhiteListIPDialogSave(whiteListIP: IIpRange) {
    try {
      this.loading = true
      await this.$store.dispatch(SAVE_WHITE_IP_LIST, whiteListIP)
      openMessage(this.$t('save_successful'), 'success')
      this.isShowWhiteListIpDialog = false
    } catch (error) {
      errorHandler(error)
    } finally {
      this.loading = false
    }
  }

  disabledDate(date: Date) {
    return isAfterDate(date)
  }

  onCloseDialog() {
    this.isShowDialog = false
  }

  onChangeMonth(month: number) {
    return month.toString().length < 2 ? '0' + month.toString() : month.toString()
  }

  async onExportBarcode() {
    try {
      this.loading = true
      const month = this.onChangeMonth(new Date(this.dateModel.date).getMonth() + 1)
      const year = new Date(this.dateModel.date).getFullYear()
      await getAccessLog(`${year}-${month}`)
      this.$store.commit(SET_HAVE_NEW_EXPORT_RECORD, { exportType: EExportType.ACCESS_LOG, isHaveNew: true })
    } catch (error) {
      errorHandler(error)
    } finally {
      this.isShowDialog = false
      this.loading = false
    }
  }

  filterItems() {
    const cleanSearchText = this.cleanString(this.dataSearch.text)
    this.displayItems = this.getItems?.filter(
      (enterprise: IEnterpriseList) =>
        this.cleanString(enterprise.fullName || '').includes(cleanSearchText) ||
        this.cleanString(enterprise.email || '').includes(cleanSearchText) ||
        this.cleanString(enterprise.id || '').includes(cleanSearchText) ||
        this.cleanString(enterprise.organizationId || '').includes(cleanSearchText)
    )

    this.sortDisplayItems()
  }

  refreshItemList() {
    this.debounceFilter()
  }

  async fetchList() {
    this.loading = true
    try {
      await this.$store.dispatch(LOAD_ENTERPRISE_LIST).finally(() => {
        this.loading = false
      })
      this.filterItems()
    } catch (error) {
      errorHandler(error)
    }
  }

  getClassTableRow(index: number, total: number) {
    let className = index === this.currentRowIndex ? 'bg-blue-200' : 'bg-white'

    if (index !== total - 1) {
      className = `${className} border-solid border-b-1 border-gray-300`
    }

    return className
  }

  onCreateEnterprise() {
    this.$router.push({ name: 'enterpriseCreate' })
  }

  openAccessLogOutput() {
    this.isShowDialog = true
  }

  openWhiteListIpdialog() {
    this.isShowWhiteListIpDialog = true
  }

  onEditEnterprise(enterprise: IEnterpriseList) {
    this.$router.push({
      name: 'enterpriseEdit',
      params: {
        id: enterprise.id,
      },
    })
  }

  async created() {
    this.routeName = 'enterprise'
    this.getQueryData()

    if (!this.$store.state.enterprise.isLoaded) {
      await this.fetchList()
    } else {
      this.filterItems()
    }

    this.debounceFilter = debounce(() => {
      this.filterItems()
    }, FILTER_DEBOUNCE_TIME)
  }
}
</script>

<style lang="scss" scoped>
.ml-0 {
  margin-left: 0px !important;
}
:deep() {
  .custom-table {
    &__header-cell {
      background: #e0e0e0 !important;
      color: #333333;
    }

    &__cell {
      color: #333333;
    }
  }
  .custom-pagination {
    .active {
      border-radius: 50% !important;
    }
  }
}
.search-date-container:deep() {
  cursor: pointer !important;
  .el-date-editor.el-input {
    width: 100%;
    text-align-last: center;
  }
}
</style>
