
import { PropSync } from 'vue-property-decorator'
import { IIpRange } from 'smartbarcode-web-core/src/utils/types/index'
import WhiteListIPDetailDialog from '@/components/whiteListIp/WhiteListIPDetailDialogComponent.vue'

import { Options, Vue } from 'vue-class-component'
@Options({
  components: { WhiteListIPDetailDialog },
  name: 'WhiteListIPComponent',
  emits: ['dialog:close', 'dialog:update-ok', 'update:modelValue'],
})
export default class WhiteListIPComponent extends Vue {
  @PropSync('modelValue', { type: Object }) _modelValue?: IIpRange[]
  isShowDetailDialog = false
  selectedIdx = -1

  get whiteListIp() {
    if (!this._modelValue) {
      this.$emit('update:modelValue', [])
      this._modelValue = []
    }
    return this._modelValue
  }

  set whiteListIp(newVal) {
    this.$emit('update:modelValue', newVal)
  }

  onWhiteListIpDetailUpdated(ipRange: IIpRange) {
    if (this.selectedIdx < 0) {
      this.whiteListIp.push(ipRange)
    } else {
      this.whiteListIp[this.selectedIdx] = ipRange
    }
  }

  removeIpRange(idx: number) {
    this.$confirm(this.$t('confirm_delete_ip_address'), this.$t('info'), {
      confirmButtonText: this.$t('update'),
      confirmButtonClass: 'danger',
      cancelButtonText: this.$t('cancel'),
    })
      .then(() => {
        this.whiteListIp.splice(idx, 1)
      })
      .catch(() => true)
  }

  openEditIpDialog(idx: number) {
    this.selectedIdx = idx
    this.isShowDetailDialog = true
  }

  onNewIpClicked() {
    this.selectedIdx = -1
    this.isShowDetailDialog = true
  }
}
