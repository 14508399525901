
import { PropSync, Prop, Watch } from 'vue-property-decorator'
import { Options, mixins } from 'vue-class-component'
import Dialog from '@/components/common/Dialog.vue'
import cloneDeep from 'lodash/cloneDeep'
import { IIpRange } from 'smartbarcode-web-core/src/utils/types'
import ValidateForm from '@/components/mixins/ValidateForm.vue'
import { openMessage } from '@/utils/utils'

@Options({
  name: 'WhiteListIPDetailDialogComponent',
  components: { Dialog },
  emits: ['dialog:close', 'dialog:update-ok', 'update:isShowDialog'],
})
export default class WhiteListIPDetailDialogComponent extends mixins(ValidateForm) {
  @PropSync('isShowDialog', { type: Boolean }) _isShowDialog!: boolean
  @Prop({ type: Object, default: {} }) readonly whiteListIP!: IIpRange[]
  @Prop({ type: Number, default: {} }) readonly idx!: number
  @Prop({ type: Boolean }) readonly isAdd?: boolean
  ipRangeModel: IIpRange = {
    startIp: '',
    endIp: '',
    note: '',
  }

  rules = {
    startIp: [{ required: true, validator: this.ipRequiredValidate, trigger: 'blur' }],
    endIp: [{ required: false, validator: this.ipValidate, trigger: 'blur' }],
    note: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
  } as {
    startIp: Array<unknown>
    endIp: Array<unknown>
    note: Array<unknown>
  }

  defaultIpRangeModel() {
    return {
      startIp: '',
      endIp: '',
      note: '',
    }
  }

  @Watch('_isShowDialog')
  onDialogOpen(newVal: boolean) {
    if (newVal) {
      this.initFormData()
    }
  }

  initFormData() {
    this.ipRangeModel = this.whiteListIP?.[this.idx]
      ? cloneDeep(this.whiteListIP?.[this.idx])
      : this.defaultIpRangeModel()
  }

  created() {
    this.initFormData()
  }

  closeDialog() {
    this.isDisplayDialog = false
  }

  get isDisplayDialog() {
    return this._isShowDialog
  }

  set isDisplayDialog(newVal) {
    this.$emit('update:isShowDialog', newVal)
  }

  onDialogSaved() {
    this.$refs.whiteListIpDetailDialogForm.validate(async (valid: string) => {
      if (valid) {
        this.$emit('dialog:update-ok', this.ipRangeModel)
        this.closeDialog()
      } else {
        openMessage(this.$t('validate_occur'), 'error')
        return false
      }
    })
  }

  onDialogClosed() {
    this.ipRangeModel = this.defaultIpRangeModel()
    this.$refs.whiteListIpDetailDialogForm.clearValidate()
  }
}
