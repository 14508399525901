<template>
  <div>
    <el-dialog
      custom-class="el-dialog--custom"
      v-model="isShowDialog"
      top="0"
      :title="title"
      :close-on-click-modal="false"
      :destroy-on-close="false"
      :show-close="true"
      @close="cancel()"
    >
      <slot />
      <template #footer>
        <div class="flex">
          <div class="flex-1">
            <el-button type="default" class="btn-default-cancel" @click="cancel()">
              {{ cancelButtonLabel }}
            </el-button>
          </div>
          <div class="flex-1">
            <el-button type="primary" @click="ok()">
              {{ okButtonLabel }}
            </el-button>
          </div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import { PropSync, Prop } from 'vue-property-decorator'
import { Options, Vue } from 'vue-class-component'
@Options({
  name: 'Dialog',
  emits: ['dialog:close', 'dialog:update-ok', 'update:modelValue'],
})
export default class PhoneInputOnlyNumber extends Vue {
  @PropSync('modelValue', { type: Boolean }) _modelValue?: boolean
  @Prop({ type: String }) readonly title?: string
  @Prop({ type: String }) readonly okButtonTitle?: string
  @Prop({ type: String }) readonly cancelButtonTitle?: string

  get okButtonLabel() {
    return this.okButtonTitle || this.$t('ok')
  }

  get cancelButtonLabel() {
    return this.cancelButtonTitle || this.$t('cancel')
  }

  get isShowDialog() {
    return this._modelValue
  }

  set isShowDialog(newVal) {
    this.$emit('update:modelValue', newVal)
  }

  closeDialog() {
    this.$emit('dialog:close')
    this.isShowDialog = false
  }

  cancel() {
    this.closeDialog()
  }

  ok() {
    this.$emit('dialog:update-ok')
  }
}
</script>
