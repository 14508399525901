<template>
  <Dialog
    class="custom-white-list-detail-dialog"
    @dialog:close="onDialogClosed()"
    @dialog:update-ok="onDialogSaved()"
    :title="$t('enterprise.ip_restriction')"
    v-model="isDisplayDialog"
  >
    <div>
      <div class="mb-5">
        <span class="leading-relaxed">
          {{ $t('white_ip_list_description') }}
        </span>
      </div>

      <el-form
        class="frm-custom-validator"
        :label-position="'right'"
        label-width="100px"
        :rules="rules"
        :model="ipRangeModel"
        ref="whiteListIpDetailDialogForm"
      >
        <div class="items-center mb-10">
          <el-form-item prop="startIp" :label="$t('start_ip')">
            <el-input :placeholder="$t('please_input_ip_v4')" v-model="ipRangeModel.startIp" />
          </el-form-item>
        </div>
        <div class="items-center mb-10">
          <el-form-item prop="endIp" :label="$t('end_ip')">
            <el-input :placeholder="$t('please_input_ip_v4')" v-model="ipRangeModel.endIp" />
          </el-form-item>
        </div>
        <div class="items-center mb-10">
          <el-form-item prop="note" :label="$t('carrier.remarks')">
            <el-input v-model="ipRangeModel.note" type="textarea" :rows="4" />
          </el-form-item>
        </div>
      </el-form>
    </div>
  </Dialog>
</template>

<script lang="ts">
import { PropSync, Prop, Watch } from 'vue-property-decorator'
import { Options, mixins } from 'vue-class-component'
import Dialog from '@/components/common/Dialog.vue'
import cloneDeep from 'lodash/cloneDeep'
import { IIpRange } from 'smartbarcode-web-core/src/utils/types'
import ValidateForm from '@/components/mixins/ValidateForm.vue'
import { openMessage } from '@/utils/utils'

@Options({
  name: 'WhiteListIPDetailDialogComponent',
  components: { Dialog },
  emits: ['dialog:close', 'dialog:update-ok', 'update:isShowDialog'],
})
export default class WhiteListIPDetailDialogComponent extends mixins(ValidateForm) {
  @PropSync('isShowDialog', { type: Boolean }) _isShowDialog!: boolean
  @Prop({ type: Object, default: {} }) readonly whiteListIP!: IIpRange[]
  @Prop({ type: Number, default: {} }) readonly idx!: number
  @Prop({ type: Boolean }) readonly isAdd?: boolean
  ipRangeModel: IIpRange = {
    startIp: '',
    endIp: '',
    note: '',
  }

  rules = {
    startIp: [{ required: true, validator: this.ipRequiredValidate, trigger: 'blur' }],
    endIp: [{ required: false, validator: this.ipValidate, trigger: 'blur' }],
    note: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
  } as {
    startIp: Array<unknown>
    endIp: Array<unknown>
    note: Array<unknown>
  }

  defaultIpRangeModel() {
    return {
      startIp: '',
      endIp: '',
      note: '',
    }
  }

  @Watch('_isShowDialog')
  onDialogOpen(newVal: boolean) {
    if (newVal) {
      this.initFormData()
    }
  }

  initFormData() {
    this.ipRangeModel = this.whiteListIP?.[this.idx]
      ? cloneDeep(this.whiteListIP?.[this.idx])
      : this.defaultIpRangeModel()
  }

  created() {
    this.initFormData()
  }

  closeDialog() {
    this.isDisplayDialog = false
  }

  get isDisplayDialog() {
    return this._isShowDialog
  }

  set isDisplayDialog(newVal) {
    this.$emit('update:isShowDialog', newVal)
  }

  onDialogSaved() {
    this.$refs.whiteListIpDetailDialogForm.validate(async (valid: string) => {
      if (valid) {
        this.$emit('dialog:update-ok', this.ipRangeModel)
        this.closeDialog()
      } else {
        openMessage(this.$t('validate_occur'), 'error')
        return false
      }
    })
  }

  onDialogClosed() {
    this.ipRangeModel = this.defaultIpRangeModel()
    this.$refs.whiteListIpDetailDialogForm.clearValidate()
  }
}
</script>

<style lang="scss" scoped>
.custom-white-list-detail-dialog:deep() {
  .pre-line {
    & .el-form-item__label {
      line-height: 20px !important;
    }
  }
  &.el-overlay {
    .el-dialog {
      &.el-dialog--custom {
        width: 500px !important;
        max-width: 500px !important;
        margin-top: 0px !important;
      }
    }
  }
}
</style>
